<script setup lang="ts">
import { Button, Icon } from "@/components/Ui";
import { onEvent } from "@/helpers/events";
import { onMounted, onUnmounted, ref} from "vue";
import { useTextDirection } from '@vueuse/core'

interface Props {
  learnMoreEvent?: string;
}
const dir = useTextDirection();
const props = defineProps<Props>();
const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const route = useRoute();
const localePath = useLocalePath();
const titleEl = ref<HTMLElement | null>(null);
const ctx = ref<any>(null);
onMounted(() => {
  if (gsap.context) {
    ctx.value = gsap.context(() => {
      const animTitle = gsap.from(titleEl.value, {
        duration: 1,
        autoAlpha: 0,
        y: "100px",
        paused: false,
      });
      ScrollTrigger.create({
        trigger: "#base-affiliate",
        end: "-200px top",
        once: true,
        // scrub: true,
        markers: false,
        onEnter: (self) => {
          self.progress === 1 ? animTitle.progress(1) : animTitle.play();
        },
      });
    });
  }
});
onUnmounted(() => {
  if (ctx.value) ctx.value.revert();
});
</script>
<template>
  <div class="flex flex-col items-center md:py-10">
    <Icon
      name="affiliate-heart"
      path="pattern"
      class="w-[130px] md:w-[166px]"
    />
    <div
      class="w-full flex flex-col gap-3 mt-10 mb-[58px] text-center md:w-10/12"
    >
      <span
        :class="`${$style[`root-subtitle`]} ${
          $style[`root-subtitle-${$i18n.locale}`]
        }`"
      >
        {{ $t("block.affiliateHome.title") }}
      </span>
      <span
        ref="titleEl"
        :class="`${$style[`root-title`]} ${
          $style[`root-title-${$i18n.locale}`]
        }`"
      >
        {{ $t("block.affiliateHome.desc") }}
      </span>
    </div>
    <Button
      :title="$t('btn.moreAboutAffiliate')"
      color="orange"
      icon="arrow-outline-right"
      :icon-class="dir === 'ltr' ? '' : 'rotate-180'"
      icon-pos="right"
      :class="$style[`root-button`]"
      @click="
        onEvent(props.learnMoreEvent || 'openAffiliate',$router.push(localePath('/affiliate')),{
          // 'cta type': 'button',
          // 'cta location': 'Block: Share the discount and be rewarded',
          // 'cta text': 'Learn more',
        })
      "
    />
  </div>
</template>

<style lang="scss" module>
.root {
  &-button {
    @apply w-full md:w-fit md:min-w-[360px] md:gap-3;
  }

  &-title {
    @apply leading-none font-black;
    @apply text-[40px] xxs:text-[52px] xs:text-[62px] md:text-[82px] lg:text-[96px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-it,
    &-zh,
    &-th,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[40px] xxs:text-[52px] xs:text-[62px] md:text-[82px] lg:text-[96px];
    }
    &-tr,
    &-ko,
    &-zh {
      @apply leading-[1.2];
    }
    &-pl,
    &-ua,
    &-tr,
    &-pt,
    &-de,
    &-bg {
      @apply text-[40px] xxs:text-[46px] xs:text-[54px] md:text-[66px] lg:text-[88px] xl:text-[96px];
    }
    &-es,
    &-ru {
      @apply text-[36px] xxs:text-[42px] xs:text-[46px] md:text-[78px] lg:text-[96px];
    }

    &-fr {
      @apply text-[44px] xxs:text-[52px] xs:text-[58px] md:text-[62px] lg:text-[84px] xl:text-[96px];
    }

    &-ja {
      @apply leading-[1.2] text-[40px] xxs:text-[52px] xs:text-[62px] md:text-[78px] lg:text-[96px];
    }
  }

  &-subtitle {
    @apply text-accent-primary leading-none;
    @apply text-[22px] xxs:text-[28px] xs:text-[32px] lg:text-[37px] xl:text-[42px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-de,
    &-it,
    &-fr,
    &-pt,
    &-tr,
    &-ua,
    &-ja,
    &-zh,
    &-th,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[22px] xxs:text-[28px] xs:text-[32px] lg:text-[37px] xl:text-[42px];
    }
    &-bn {
      @apply pb-6;
    }
    &-pl,
    &-es,
    &-bg,
    &-ru {
      @apply text-[20px] xxs:text-[26px] xs:text-[28px] lg:text-[37px] xl:text-[42px];
    }
  }
}
</style>
